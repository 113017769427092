import Button from '@/components/Button';
import { format } from 'date-fns';
import type { VNode } from 'vue';

export default defineComponent({
  name: 'DeliveryWeekActivateButton',
  props: {
    showDiscount: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const {
      isSelectedDeliveryWeekPauseEditable,
      selectedDeliveryWeek,
      selectedDeliveryWeekNumberString,
      selectedDeliveryWeekDiscount,
      deliveryDate,
    } = useDeliveryWeeks();
    const { subscriptionId } = useCustomerSubscriptions();
    const { setWeekPause } = useDeliveriesApi();
    const noticeStore = useNoticeStore();
    const { t } = useI18n();
    const { formatPrice } = useCustomerCurrency();
    const discountPrice = computed<string>(() => {
      return formatPrice(selectedDeliveryWeekDiscount.value);
    });
    const handleDeliveryWeekActivate = async (event: Event): Promise<void> => {
      event.preventDefault();
      if (
        !subscriptionId.value ||
        !selectedDeliveryWeek.value ||
        !isSelectedDeliveryWeekPauseEditable.value
      ) {
        return;
      }

      try {
        await setWeekPause(
          false,
          subscriptionId.value,
          new Date(selectedDeliveryWeek.value.paymentDate)
        );

        noticeStore.addNotice({
          text: t('week_banner.delivery_x_activated', {
            deliveryDate: deliveryDate
              ? format(new Date(deliveryDate.value), 'd.M')
              : '',
          }),
          type: 'success',
        });
      } catch (_error) {
        noticeStore.addNotice({
          text: t('week_banner.delivery_x_update_failed', {
            deliveryDate: deliveryDate
              ? format(new Date(deliveryDate.value), 'd.M')
              : '',
          }),
          type: 'caution',
        });
      }
    };

    return {
      handleDeliveryWeekActivate,
      selectedDeliveryWeek,
      selectedDeliveryWeekNumberString,
      discountPrice,
      deliveryDate,
    };
  },
  render(): VNode {
    return (
      <Button class="button-primary" onClick={this.handleDeliveryWeekActivate}>
        {this.showDiscount && this.discountPrice !== '0 €'
          ? this.$t('week_banner.activate_and_save_x', {
              discountAmount: this.discountPrice,
            })
          : this.$t('week_banner.activate_delivery_x', {
              deliveryDate: this.deliveryDate
                ? format(new Date(this.deliveryDate), 'd.M')
                : '',
            })}
      </Button>
    );
  },
});
